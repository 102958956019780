import React from "react"
import { Seo } from "../gatsby-components"
import { Layout, Container } from "../components"
import { layout } from "../temp-content/layout"
import notFoundIllu from "../images/404-illu.png"

const notFoundStyles = {
  backgroundImage: `url(${notFoundIllu})`,
  backgroundSize: "75%",
}

const NotFoundPage = () => {
  return (
    <>
      <Seo title="404 - Page not found" />
      <Layout {...layout}>
        <div
          className="themed dark h-screen max-h-screen bg-theme-fill bg-contain bg-right-bottom bg-no-repeat"
          style={notFoundStyles}
        >
          <Container className="pt-12">
            <h1 className="text-6xl font-semibold uppercase tracking-wider">
              404{" "}
              <span className="block text-base font-normal">
                Page not found
              </span>
            </h1>
          </Container>
        </div>
      </Layout>
    </>
  )
}

export default NotFoundPage
